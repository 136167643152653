import React, { Component } from 'react';
import { t, language } from '../lib/t';
import videoEn from '../videos/intro-en.mp4';
import videoAr from '../videos/intro-ar.mp4';
import '../css/Video.css';

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const onEnd = (e) => {
      this.props.parentComponent.setState({ video: false });
    }
    document.getElementById('intro').play();
    document.getElementById('intro').addEventListener('ended', onEnd, false);
  }

  skipIntro() {
    this.props.parentComponent.setState({ video: false });
  }

  render() {
    const video = language === 'ar' ? videoAr : videoEn;

    return (
      <div className="Video">
        <div id="skip" onClick={this.skipIntro.bind(this)}>
          {t('skip')}
        </div>
        <video controls preload="metadata" id="intro">
          <source src={video} type="video/mp4"/>
          Video not supported.
        </video>
      </div>
    );
  }
}

export default Video;
