import React from 'react';

let language = 'en';
language = navigator.languages || navigator.language || navigator.userLanguage || 'en';
if (language.constructor === Array) {
  language = language[0];
}
if (/lang/.test(window.location.search)) {
  language = window.location.search.replace(/^\?lang=/, '');
}
language = language.replace(/[-_].*$/, '').toLowerCase();
if (['en', 'ar'].indexOf(language) === -1) {
  language = 'en';
}
window.language = language;

window.onload = function() {
  document.getElementsByTagName('BODY')[0].className = language;
}

function t(enStr, data, html) {
  const { translations } = window;
  const lang = language;
  let str = '';
  if (translations.en[enStr]) {
    enStr = translations.en[enStr];
  }
  if (lang === 'en') {
    str = enStr;
  }
  else if (translations[lang][enStr]) {
    str = translations[lang][enStr];
  }
  else {
    str = 'Translation missing: ' + enStr;
    console.log(`Translation missing for '${enStr}'`);
  }
  if (data) {
    str = str.replace(/\{([^}]+)\}/g, (x, y) => {
      return data[y];
    });
  }
  if (html) {
    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  }
  return str;
}
export { t, language };
