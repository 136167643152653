import React, { Component } from 'react';
import Slider from 'react-slick';
import $ from 'jquery';
import styled from 'styled-components';
import { t, language } from '../lib/t';
import { numberWithCommas } from '../lib/helpers';
import '../css/Timeline.css';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let greyDots = '';
    let maxValue = 0;
    let numberOfYears = 0;
    for (const year in window.googleData.byYear) {
      numberOfYears++;
      if (window.googleData.byYear[year] > maxValue) {
        maxValue = window.googleData.byYear[year];
      }
    }
    let maxNumberOfDots = Math.ceil(maxValue / 1000);
    maxNumberOfDots = maxNumberOfDots + (90 - (maxNumberOfDots % 90)); // 90 is the number of dots in a row
    for (let i = 0; i < maxNumberOfDots; i++) {
      greyDots += '. ';
    }

    let years = [];
    for (const year in window.googleData.byYear) {
      years.push(year);
    }
    years = years.sort();

    const StyledSlider = language === 'ar' ?
      styled(Slider)`
        .slick-next {
          left: ${numberOfYears * 45 + 40}px;
        }

        .slick-prev {
          left: ${numberOfYears * 45}px;
        }
      ` :
      styled(Slider)`
        .slick-next {
          right: ${numberOfYears * 45}px;
        }

        .slick-prev {
          right: ${numberOfYears * 45 + 40}px;
        }
      `;

    const settings = {
      dots: true,
      beforeChange: function() {
        $('.tlnumber').html(0);
        $('.colorful-dots').html('');
        $('.grey-dots').html(greyDots);
      },
      afterChange: function(i) {
        $('#year h3').html(years[i]);
        if (window.googleData.byYear[years[i]]) {
          $('.slider-out .dots').removeClass('insuf-data');
        }
        else {
          $('.slider-out .dots').addClass('insuf-data');
        }      
        $('.tlnumber').prop('Counter', 0).animate({
          Counter: window.googleData.byYear[years[i]],
        }, {
          duration: 2000,
          easing: 'swing',
          step: function(now) {
            $(this).text(numberWithCommas(Math.ceil(now)));
            const numberOfDots = Math.ceil(now / 1000);
            const diff = maxNumberOfDots - numberOfDots;
            $('.grey-dots').html(greyDots.substring((maxNumberOfDots * 2) - diff * 2));
            $('.colorful-dots').html(greyDots.substring((maxNumberOfDots * 2) - numberOfDots * 2));
          }
        });
      },
      customPaging: function(i) {
        return (
          <span><b>{years[i]}</b></span>
        );
      },
    };

    return (
      <div className="Timeline">
        <h1>{t('timeline_title')}</h1>
        <h2>{t('timeline_desc')}</h2>
        <div className="timeline-content slider-out">
          <div className="year" id="year">
            <h3>{years[0]}</h3>
          </div>
          <div className="dots insuf-data">
            <p>{t('iraq_displaced_count')}</p>
            <p><b className="tlnumber">0</b> <span>{t('one_dot_ref')}</span></p>
          </div>
        </div>
        <StyledSlider {...settings}>
          {years.map(year => {
            const value = window.googleData.byYear[year];

            return (
              <div className="timeline-content" id="timeline-content" key={year}>
                <div className="year">
                  <p className="text">{t(window.googleData.textByYear[year], {}, true)}</p>
                </div>
                <div className={value ? 'dots' : 'dots insuf-data'}>
                  <p className="insuf-data-sentence">{t('insuf')}</p>
                  <span className="colorful-dots">
                  </span>
                  <span className="grey-dots">
                    {greyDots}
                  </span>
                </div>
              </div>
            );
          })}
        </StyledSlider>
        <p id="credits"><a href="http://popstats.unhcr.org/en/persons_of_concern" target="_blank" rel="noopener noreferrer">{t('timeline_source', {}, true)}</a></p>
      </div>
    );
  }
}

export default Timeline;
