import React, { Component } from 'react';
import { t } from '../lib/t';
import facebook2 from '../img/facebook2.png';
import twitter2 from '../img/twitter2.png';
import reiraqLogo from '../img/reiraq-logo.png';
import d4cLogo from '../img/d4c-logo.svg';
import ihroLogo from '../img/ihro-logo.png';
import '../css/Footer.css';

class Footer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="Footer2">
        <div className="footer-element">
          <img src={reiraqLogo} alt="" />
        </div>
        <div className="footer-element">
          <p>{t('initiative')}</p>
          <a href="http://rights-iq.org/" target="_blank" rel="noopener noreferrer">
            <img src={ihroLogo} alt="" />
          </a>
        </div>
        <div className="footer-element">
          <p>{t('support')}</p>
          <a href="http://www.data4chan.ge/" target="_blank" rel="noopener noreferrer">
            <img src={d4cLogo} alt="" />
          </a>
        </div>
        <div className="footer-element" id="follow">
          <p>{t('follow')}</p>
          <a href="https://twitter.com/iraqhumanrights?lang=en" target="_blank" rel="noopener noreferrer"><img src={twitter2} alt="" /></a>
          {' '}
          <a href="https://www.facebook.com/IraqHumanRights/" target="_blank" rel="noopener noreferrer"><img src={facebook2} alt="" /></a>
          <br />
          <span><a href="http://rights-iq.org/contact.html" target="_blank" rel="noopener noreferrer">{t('contact')}</a></span>
        </div>
      </div>
    );
  }
}

export default Footer2;
