import React, { Component } from 'react';
import $ from 'jquery';
import { t } from '../lib/t';
import { numberWithCommas } from '../lib/helpers';
import { particle } from '../lib/particles';
import '../css/Particles.css';

// Constants

const size = 1200;

class Particles extends Component {
  componentDidMount() {
    const numberOfParticles = Math.floor(window.googleData.numberOfDisplacedPeople / 1000);
		const canvas = document.getElementById('canvas');
		const context = canvas.getContext('2d');
		const particles = [];
		for (let i = 0; i < numberOfParticles; i++) {
  		particles.push(particle.create(size / 2, size / 2, Math.random() * 10 + 0.1, Math.random() * Math.PI * 2));
		}
    
    let count = 0;

		const update = () => {
      count++;
			context.clearRect(0, 0, size, size);
			
			for (let i = 0; i < numberOfParticles; i++) {
			  particles[i].update();
			  context.beginPath();
        context.fillStyle = '#ff0030';
        var x = particles[i].position.getX();
        var y = particles[i].position.getY();
        if (count > 2) {
          x += Math.random() * 30;
          y += Math.random() * 30;
        }
			  context.arc(x, y, 1, 0, 2 * Math.PI, false);
			  context.fill();
			}

      if (count < 44) {
        // window.setTimeout(function() { window.requestAnimationFrame(update); }, Math.random() * 100);
        window.requestAnimationFrame(update);
      }
      else {
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 250;

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        context.fillStyle = 'rgba(255, 255, 255, 0.7)';
        context.fill();
        
        $('#mask, #legend, #credit').fadeIn(1000, () => {
          $('.number span').prop('Counter', 0).animate({
            Counter: window.googleData.numberOfDisplacedPeople,
          }, {
            duration: 2000,
            easing: 'swing',
            step: function(now) {
              $(this).text(numberWithCommas(Math.ceil(now)));
            }
          });
        });
      }
		};
		
		update();
  }

  render() {
    return (
      <div className="Particles" style={{ height: size, width: size }}>
        <div id="mask">
          <p>{t('top_sentence', { total: 0 }, true)}</p>
        </div>
        <p id="legend">{t('one_dot_ref')}</p>
        <p id="credit"><a href="http://iraqdtm.iom.int/" target="_blank" rel="noopener noreferrer">{t('top_source', {}, true)}</a></p>
        <canvas id="canvas" width={size} height={size}></canvas>
      </div>
    );
  }
}

export default Particles;
