import React, { Component } from 'react';
import Typist from 'react-typist';
import $ from 'jquery';
import Particles from './Particles';
// import Particles2 from './Particles2';
import { t } from '../lib/t';
import '../css/Header.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showParticles: false,
      showTitle: false,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      $('#dot').fadeIn(1000, () => {
        $('#line').slideUp(1000, () => {
          this.setState({ showTitle: true });
        });
      });
    }, 1000);
  }

  showParticles() {
    this.setState({ showParticles: true });
  }

  render() {
    return (
      <div className={ this.state.showParticles ? 'Header bordered' : 'Header' }>
        { this.state.showParticles ?
          <div id="particles">
            <Particles />
          </div> : 
          (<div className="Typist">
            { this.state.showTitle ?
              <Typist cursor={{ show: false }} avgTypingDelay={100} onTypingDone={this.showParticles.bind(this)}>
                <h1>{t('top_dot_ref')}</h1>
              </Typist> : <div id="title-placeholder" /> }
            <div id="line-container"><div id="line"></div></div>
            <div id="dot"></div>
          </div>)
        }
      </div>
    );
  }
}

export default App;
