const risksData = {
  'All': {
    x: null,
    y: null,
    r: null,
    a: null,
  },
  'Camps': {
    x: { en: 12, ar: -17 },
    y: -29,
    r: 132,
    a: 0.05,
  },
  'Host families': {
    x: { en: -3, ar: -1 },
    y: -19,
    r: 102,
    a: 0.05,
  },
  'Hotel/Motel': {
    x: { en: -30, ar: 20 },
    y: -54,
    r: 24,
    a: 0.06,
  },
  'Informal settlements': {
    x: { en: -20, ar: 12 },
    y: -10,
    r: 60,
    a: 0.05,
  },
  'Other shelter type': {
    x: { en: -30, ar: 17 },
    y: -54,
    r: 24,
    a: 0.06,
  },
  'Religious building': {
    x: { en: -26, ar: 14 },
    y: -44,
    r: 35,
    a: 0.06,
  },
  'Rented houses': {
    x: { en: 23, ar: -25 },
    y: 11,
    r: 165,
    a: 0.04,
  },
  'School building': {
    x: { en: -30, ar: 18 },
    y: -52,
    r: 28,
    a: 0.06,
  },
  'Unfinished/Abandoned building': {
    x: { en: -17, ar: 7 },
    y: 1,
    r: 71,
    a: 0.05,
  },
  'Unknown shelter type': {
    x: { en: -30, ar: 25 },
    y: -45,
    r: 20,
    a: 0.06,
  },
};
module.exports = risksData;
