import React, { Component } from 'react';
import { t } from '../lib/t';
import '../css/Stories.css';
import mapAnbar from '../img/map-focus-anbar.png';
import mapErbil from '../img/map-focus-erbil.png';
import mapNinewa from '../img/map-focus-ninewa.png';
import bgErbil from '../img/stories/erbil.jpg';
import bgFallujah from '../img/stories/fallujah.jpg';
import bgMosul2 from '../img/stories/mosul2.jpg';
import bgMosulnemeer from '../img/stories/mosulnemeer.jpg';
import bgNinewa from '../img/stories/ninewa.jpg';

const bgs = {
  erbil: bgErbil,
  fallujah: bgFallujah,
  mosul2: bgMosul2,
  mosulnemeer: bgMosulnemeer,
  ninewa: bgNinewa,
};

const maps = {
  anbar: mapAnbar,
  erbil: mapErbil,
  ninewa: mapNinewa,
};

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storyIndex: 0,
    }
  }

  handleOver(storyIndex) {
    this.setState({ storyIndex });
  }

  render() {
    return (
      <div className="Stories">
        <h1>{t('stories_title')}</h1>
        <h2>{t('stories_desc')}</h2>
        <div className="story-container">
          <div className="story-circles-container">
            <div className="story-circles">
              {window.googleData.stories.map((_story, index) => (
                <div key={index}>
                  <div className="circle-container" id={`circle-${index}`} onClick={this.handleOver.bind(this, index)}>
                    <div className={this.state.storyIndex === index ? 'circle circle-active' : 'circle'}>
                      <img src={bgs[window.googleData.stories[index].image]} alt="" />
                    </div>
                  </div>
                  <div className="map" id={`map-${index}`} style={ this.state.storyIndex === index ? { display: 'block' } : null }>
                    <img src={maps[window.googleData.stories[index].region]} alt="" />
                    <p className="place">{t(window.googleData.stories[index].name)}</p>
                  </div>
                </div>
              ))}
            </div>
            <p id="credits">
              {t('source')}
              {': '}
              {t(window.googleData.stories[this.state.storyIndex].source, {}, true)}
            </p>
          </div>
          <div className="story-text">
            <div>
              {t(window.googleData.stories[this.state.storyIndex].text, {}, true)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Stories;
