import React, { Component } from 'react';
import { t, language } from '../lib/t';
import '../css/TopBar.css';
import logo from '../img/logo.svg';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: language,
    };
  }

  changeLanguage(lang) {
    window.location = window.location.href.replace(/\?lang=.*/, '') + '?lang=' + lang
  }

  reload() {
    window.location.reload(true);
  }

  render() {
    return (
      <div className="TopBar">
        <img src={logo} alt="Logo" onClick={this.reload.bind(this)} />
        <ul>
        { this.props.hideAbout ? null : 
          <li>
            <span className="link">
              <span className="label"><a href="#about">{t('about')}</a></span>
              <span className="slider"></span>
            </span>
          </li> }
          <li>
            <span onClick={this.changeLanguage.bind(this, 'ar')} className={this.state.currentLanguage === 'ar' ? 'current link' : 'link'}>
              <span className="label">عربى</span>
              <span className="slider"></span>
            </span>
            {' | '}
            <span onClick={this.changeLanguage.bind(this, 'en')} className={this.state.currentLanguage === 'en' ? 'current link' : 'link'}>
              <span className="label">EN</span>
              <span className="slider"></span>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default TopBar;
