const vector = {
  _x: 0,
  _y: 0,

  create: function(x,y) {
    var obj = Object.create(this);
    obj._y = y;
    obj._x = x;
    return obj;
  },

  getX: function() {
    return this._x;
  },

  getY: function() {
    return this._y
  },

  setX: function(value) {
    this._x = value;
  },
  
  setY: function(value) {
    this._y = value;
  },

  getLength: function() {
    return Math.sqrt(this._x * this._x + this._y * this._y);
  },

  getAngle: function() {
    return Math.atan2(this._y,this._x);
  },

  setAngle: function(angle) {
    const length = this.getLength();
    this._y = Math.cos(angle) * length;
    this._x = Math.sin(angle) * length;
  },
  
  setLength: function(length) {
    const angle = this.getAngle();
    this._y = Math.cos(angle) * length;
    this._x = Math.sin(angle) * length;
  },
  
  add: function(v2) {
    const vect = this.create(this._x + v2._x, this._y + v2._y);
    return vect;
  },
  
  subtract: function(v2) {
    const vect = this.create(this._x - v2._x, this._y - v2._y);
    return vect;
  },

  multiply: function(value) {
    return vector.create(this._x * value, this._y * value);
  },
  
  divide: function(value) {
    return vector.create(this._x / value, this._y / value);
  },
  
  scale: function(value) {
    this._x = this._x * value;
    this._y = this._y * value;
  },
  
  addTo: function(v2) {
    this._x = this._x + v2._x;
    this._y = this._y + v2._y;
  },

  subtractFrom: function(v2) {
    this._x = this._x - v2._x;
    this._y = this._y - v2._y;
  }
};

const particle = {
  velocity: null,
  position: null,

  create: function(x, y, speed, angle) {
    const obj = Object.create(this);
    obj.velocity = vector.create(0, 0);
    
    obj.velocity.setLength(speed);
    obj.velocity.setAngle(angle);
    obj.position = vector.create(x, y);
    return obj;
  },

  update: function() {
    this.position.addTo(this.velocity);
  }
};

const draw = (canvasId, numberOfParticles, color, numberOfFrames, size, dotSize, centerRadius, bgColor) => {
  const canvas = document.getElementById(canvasId);
  const context = canvas.getContext('2d');
  const particles = [];
  for (let i = 0; i < numberOfParticles; i++) {
    particles.push(particle.create(size / 2, size / 2, Math.random() * 10 + centerRadius, Math.random() * Math.PI * 2));
  }
  
  let count = 0;

  const update = () => {
    count++;
    context.clearRect(0, 0, size, size);
    if (bgColor) {
      context.fillStyle = bgColor;
      context.fillRect(0, 0, size, size);
    }
    
    for (let i = 0; i < numberOfParticles; i++) {
      particles[i].update();
      context.beginPath();
      context.fillStyle = color;
      context.arc(particles[i].position.getX(), particles[i].position.getY(), dotSize, 0, 2 * Math.PI, false);
      context.fill();
    }

    if (count < numberOfFrames) {
      window.requestAnimationFrame(update);
    }
  };
  
  update();
};

module.exports = { vector, particle, draw };
