import React, { Component } from 'react';
import $ from 'jquery';
import * as d3 from 'd3';
import { t } from '../lib/t';
import { numberWithCommas } from '../lib/helpers';
import arrowUp from '../img/arrow-up.png';
import arrowDown from '../img/arrow-down.png';
import '../css/Risk.css';

class Risk3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelter: 'All',
      mustRedraw: false,
      mode: 'selected', // or expanded
    };
  }

  draw() {
    if (this.state.shelter !== 'All') {
      const value = Math.ceil(window.googleData.risks.shelters[this.state.shelter].value / 1000);
      this.addNode(value, true);
    }
    else if (this.hasOwnProperty('removeNode')) {
      this.removeNode();
    }
  }

  selectShelter(shelter) {
    const that = this;
    if (shelter !== this.state.shelter) {
      this.setState({ shelter, mustRedraw: true, mode: 'selected' }, function() {
        that.start();
      });
    }
  }

  toggle() {
    const mode = this.state.mode === 'selected' ? 'expanded' : 'selected';
    this.setState({ mode, mustRedraw: false });
  }

  counterUp(sel) {
    $(sel).each(function() {
      const that = $(this);
      const value = parseInt(that.text(), 10);
      if (value) {
        that.html(0);
        that.prop('Counter', 0).animate({
          Counter: value,
        }, {
          duration: 2000,
          easing: 'swing',
          step: function(now) {
            const number = Math.ceil(now);
            $(this).text(numberWithCommas(number));
          }
        });
      }
    });
  }

  componentDidMount() {
    this.draw();
    this.counterUp('.displaced-value, .returned-value');
    const border = $('#border');
    var max = 0;
    var mainSvg = null;
    d3.xml("cluster.svg", "image/svg+xml", function(xml) {
    var importedNode = document.importNode(xml.documentElement, true);
    d3.select("div#vis")
      .each(function() {
        this.appendChild(importedNode);
      })
      mainSvg = d3.select('#vis svg');
      max = mainSvg.selectAll('g#outside path').size();
      run();
    });

    const that = this;
    var run = function() {
      var width = 1024,
          height = 575,
          padding = 3, // separation between same-color nodes
          maxRadius = 1.5;
      
      var n = 0, // total number of nodes in the cluster
          m = 1; // number of distinct clusters
      
      var color = function() { return '#d9324e'; };
      
      // The largest node for each cluster.
      var clusters = new Array(m);
      
      var nodes = d3.range(n).map(function(j) {
        var i = 0;
        var r = maxRadius,
        d = {
          cluster: i,
          radius: r,
          x: Math.cos(i / m * 2 * Math.PI) + width / 2 + Math.random(),
          y: Math.sin(i / m * 2 * Math.PI) + height / 2 + Math.random()
        };
        if (!clusters[i] || (r > clusters[i].radius)) clusters[i] = d;
        return d;
      });

      const start = function(e) {
        let { x, y } = window.googleData.risks.shelters[that.state.shelter];
        if (x && y) {
          x = x[window.language] || x.en;
          group.attr('transform', 'translate(' + x + ', ' + y + ')');
        }
      }
      that.start = start;
      
      var force = d3.layout.force()
      .nodes(nodes)
      .size([width, height])
      .gravity(0.1)
      .charge(0.005)
      .friction(0.87)
      .on("tick", tick)
      .on("end", end)
      .on("start", start)
      .start();
      
      var svg = d3.select("div#vis2").append("svg")
      .attr("width", width + 'px')
      .attr("height", height + 'px')
      .attr("viewBox", "0 0 " + width + " " + height);
      const group = svg.append("g");
      var node = group.selectAll("circle")
      .data(nodes)
      .enter().append("circle")
      .attr("class", "node")
      .style("fill", function (d) {
        return color(d.cluster);
      })
      .call(force.drag);
      
      node.transition()
      .attrTween("r", function (d) {});
     
      function tick(e) {
        node
        .each(collide(.2))
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        });
        
        const { y, a, r } = window.googleData.risks.shelters[that.state.shelter];
        if (e.alpha < a) {
          const b = group.node().getBBox();
          border.css({
            left: window.language === 'ar' ? 'auto' : 450,
            right: window.language === 'ar' ? 450 : 'auto',
            top: b.y + y - 10,
            width: r,
            height: r,
          });
          border.fadeIn();
          force.stop();
        }
      }

      function end(e) {
      }
      
      // Resolves collisions between d and all other circles.
      function collide(alpha) {
        var quadtree = d3.geom.quadtree(nodes);
        return function (d) {
          var r = d.radius + maxRadius + padding,
          nx1 = d.x - r,
          nx2 = d.x + r,
          ny1 = d.y - r,
          ny2 = d.y + r;
          quadtree.visit(function (quad, x1, y1, x2, y2) {
            if (quad.point && (quad.point !== d)) {
              var x = d.x - quad.point.x,
                y = d.y - quad.point.y,
                l = Math.sqrt(x * x + y * y),
                r = d.radius + quad.point.radius + padding;
              if (l < r) {
                l = (l - r) / l * alpha;
                d.x -= x *= l;
                d.y -= y *= l;
                quad.point.x += x;
                quad.point.y += y;
              }
            }
            return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
          });
        };
      }
      
      function update() {
        node = group.selectAll("circle.node").data(nodes);
        node.enter().append("circle")
        .attr("class", "node")
        .attr("cx", function(d) {
          return d.x;
        })
        .attr("cy", function(d) {
          return d.y;
        })
        .attr("r", function(d) {
          return d.radius;
        })
        .style("fill", color())
        .call(force.drag);
        node.exit().remove();
        force.start();
      }
      
      const addNode = function(total, clear) {
        if (clear) {
          removeNode();
        }
        var limit = 200;
        var n = limit;
        if (total < limit) {
          n = total;
        }
        const xshift = window.language === 'ar' ? -1070 : 1070;
        for (var i = 0; i < n; i++) {
          nodes.push({
            cluster: 0,
            radius: maxRadius,
            x: xshift + Math.random() * 580,
            y: 110 + Math.random() * 575
          });
        }
        var removed = []
        while (removed.length < n) {
          var r = Math.floor(Math.random() * max) + 1;
          if (removed.indexOf(r) === -1) {
            mainSvg.select('g#outside path:nth-child(' + r + ')').style('opacity', 0);
            removed.push(r);
          }
        }
        update();
        var remaining = total - limit;
        if (remaining > 0) {
          setTimeout(function() {
            addNode(remaining, false);
          }, 200);
        }
      }
      that.addNode = addNode;

      const removeNode = function() {
        border.hide();
        mainSvg.selectAll('g#outside path').style('opacity', 1);
        nodes.splice(0, nodes.length);
        update();
      }
      that.removeNode = removeNode;
    }
  }

  componentDidUpdate() {
    if (this.state.mustRedraw) {
      this.draw();
      this.counterUp('.shelter-value');
    }
  }

  render() {
    return (
      <div className="Risk">
        <h1>{t('people_at_risk_today')}</h1>
        <h2>{t('people_risk_desc')}</h2>
        <div id="canvas-container">
          { this.state.mode === 'selected' ?
            (
              <div id="selected">
                {t(this.state.shelter)}
                <img src={arrowDown} alt="" onClick={this.toggle.bind(this)} />
              </div>
             ) : (
               <div id="select">
                 <div className="choose">{t('select_shelter')} <img src={arrowUp} alt="" onClick={this.toggle.bind(this)} /></div>
                 {Object.keys(window.googleData.risks.shelters).map((shelter) => <div className="option" key={shelter} onClick={this.selectShelter.bind(this, shelter)}>{t(shelter)}</div>)}
               </div>
             )
          }
          <p>{t('people_displaced')}<br /><span className="displaced-value">{window.googleData.risks.shelters['All'].value}</span></p>
          <p>{t('people_returned')}<br /><span className="returned-value">{window.googleData.peopleReturned}</span></p>
          { this.state.shelter === 'All' ? null : <p id="legend"><span className="shelter-value">{window.googleData.risks.shelters[this.state.shelter].value}</span> {`${t('people_in')} ${t(this.state.shelter).toLowerCase()}`}</p> }
          <div id="vis">
            <div id="vis2">
              <div id="border"></div>
            </div>
          </div>
          <p id="credit"><a href="http://iraqdtm.iom.int/" target="_blank" rel="noopener noreferrer">{t('people_at_risk_source')}</a></p>
          <p id="disclaimer">{t('one_dot_ref')}</p>
        </div>
      </div>
    );
  }
}

export default Risk3;
