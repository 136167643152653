import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import risksData from './lib/risksData';
import config from './config';
import './css/index.css';

window.gapi.load('client', function() {
  window.gapi.client
    .init({
      apiKey: config.apiKey,
      discoveryDocs: config.discoveryDocs
    })
    .then(() => {
      window.gapi.client.load('sheets', 'v4', () => {
        window.gapi.client.sheets.spreadsheets.values
          .batchGet({
            spreadsheetId: config.spreadsheetId,
            ranges: ['Global!A2:B2', 'Timeline!A2:C', 'Governorates!A2:D', 'Stories!A2:E', 'Risks!A2:B', 'Translations!A2:C']
          })
          .then(
            response => {
              window.googleData = { govs: [], byYear: {}, textByYear: {}, stories: [], risks: { shelters: {} } };
              const data = response.result.valueRanges;

              // Global
              window.googleData.numberOfDisplacedPeople = data[0].values[0][0];
              window.googleData.peopleReturned = data[0].values[0][1];

              // Timeline
              data[1].values.forEach(function(row) {
                const year = parseInt(row[0]);
                window.googleData.byYear[year] = parseInt(row[1]);
                window.googleData.textByYear[year] = row[2];
              });
              
              // Governorates
              data[2].values.forEach(function(row) {
                window.googleData.govs.push({ id: row[0], name: row[1], idp: parseInt(row[2]), returnees: parseInt(row[3]) });
              });

              // Stories
              data[3].values.forEach(function(row) {
                window.googleData.stories.push({ region: row[0], name: row[1], image: row[2], source: row[3], text: row[4] });
              });

              // Risk
              const risks = {};
              data[4].values.forEach(function(row) {
                risks[row[0]] = Object.assign({ value: parseInt(row[1]) }, risksData[row[0]]);
              });
              window.googleData.risks.shelters = risks;

              // Translations
              window.translations = { ar: {}, en: {} };
              data[5].values.forEach(function(row) {
                if (row[0] !== '-') {
                  window.translations.en[row[0]] = row[1];
                }
                window.translations.ar[row[1]] = row[2];
              });

              ReactDOM.render(<App />, document.getElementById('root'));
            },
            response => {
              console.log(response.result.error);
            }
          );
      });
    });
});
