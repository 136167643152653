import React, { Component } from 'react';
import { t, language } from '../lib/t';
import flowEn from '../img/flow.png';
import flowAr from '../img/flow-ar.png';
import facebook from '../img/facebook.png';
import twitter from '../img/twitter.png';
import '../css/Footer2.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const socialText = t('social');
    let shareUrl = 'http://re-iraq.org';
    if (language === 'ar') {
      shareUrl = 'http://re-iraq.org/ar';
    }

    return (
      <div className="Footer">
        <div className="footer-inner">
          <h1>{t('put_pressure')}</h1>
          <h2>{t('30bn')}</h2>
          <p className="paragraph1">{t('footer_text', {}, true)}</p>
          <p className="flow"><img src={language === 'ar' ? flowAr : flowEn} alt="" /></p>
          <h3>{t('what')}</h3>
          <p className="paragraph2">{t('share')}</p>
          <p className="social">
            <a href={`https://twitter.com/home?status=${socialText}`} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="" /></a>
            {' '}
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&summary=${socialText}`} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="" /></a>
          </p>
          <div className="columns">
            <h4 id="about">{t('about')}</h4>
            <p>{t('about_ihro')}</p>
            <p className="center">{t('about_ihro_2')}</p>
            <p>{t('about_reiraq')}</p>
            <br style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
