import React, { Component } from 'react';
import $ from 'jquery';
import { t } from '../lib/t';
import { numberWithCommas } from '../lib/helpers';
import Iraq from './Iraq';
import '../css/Map.css';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGovId: null,
    };
  }

  componentDidUpdate() {
    $('.displaced .value, .returned .value').each(function() {
      const that = $(this);
      const value = parseInt(that.text(), 10);
      if (value) {
        that.html(0);
        that.prop('Counter', 0).animate({
          Counter: value,
        }, {
          duration: 2000,
          easing: 'swing',
          step: function(now) {
            $(this).text(numberWithCommas(Math.ceil(now)));
          }
        });
      }
    });
  }

  selectGov(id) {
    this.setState({ currentGovId: id });
  }

  render() {
    let max = 0;
    let currentGov = {};

    window.googleData.govs.forEach((gov) => {
      const sum = gov.idp + gov.returnees;
      if (sum > max) {
        max = sum;
      }
      if (gov.id === this.state.currentGovId) {
        currentGov = gov;
      }
    });

    return (
      <div className="Map">
        <h1>{t('govs_title')}</h1>
        <h2>{t('govs_desc', {}, true)}</h2>
        <div className="content">
          <div className="left">
            <div className="left-inner">
              <p><span className="label">{t('gov')}</span><br /><span className="value">{(currentGov && currentGov.name) ? t(currentGov.name) : '-'}</span></p>
              <p className="displaced"><span className="label">{t('people_displaced')}</span><br /><span className="value">{currentGov.idp ? currentGov.idp : '-'}</span></p>
              <p className="returned"><span className="label">{t('people_returned')}</span><br /><span className="value">{currentGov.returnees ? currentGov.returnees : '-'}</span></p>
            </div>
          </div>
          <div className="center">
            <p>{t('displaced_count')}</p>
            <div className="gradient"></div>
            <div className="gradient-legend"><span>{t('less')}</span> <span>{t('more')}</span></div>
            <div id="map">
              <Iraq onRegionClick={this.selectGov.bind(this)} clickedRegion={this.state.currentGovId} />
            </div>
          </div>
          <div className={this.state.currentGovId ? 'right focused' : 'right'}>
            {window.googleData.govs.sort((a, b) => (a.name > b.name ? 1 : -1)).map((gov) => {
              const sum = gov.idp + gov.returnees;
              const barWidth = sum / max * 150;
              const displacedBarWidth = gov.idp / max * 150;
              
              return (
                <div className={gov.id === this.state.currentGovId ? 'line current' : 'line'} key={gov.id} onClick={this.selectGov.bind(this, gov.id)}>
                  <div className="cell" id={`gov-name-${gov.id}`}>{t(gov.name)}</div>
                  <div className="cell">
                    <div className="bar" style={{ width: barWidth }}>
                      <div className="displaced-bar" style={{ width: displacedBarWidth }} />
                      <div className="returned-bar" style={{ width: barWidth }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <p id="credits"><a href="http://iraqdtm.iom.int/" target="_blank" rel="noopener noreferrer">{t('map_source')}</a></p>
      </div>
    );
  }
}

export default Map;
