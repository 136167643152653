import React, { Component } from 'react';
import Video from './Video';
import TopBar from './TopBar';
import Header from './Header';
import Timeline from './Timeline';
import Map from './Map';
import Stories from './Stories';
// import Risk from './Risk';
// import Risk2 from './Risk2';
import Risk3 from './Risk3';
import Footer from './Footer';
import Footer2 from './Footer2';
import '../css/App.css';
import '../css/App-mobile.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: true,
    };
  }

  render() {
    if (this.state.video) {
      return (
        <div className="App">
          <TopBar hideAbout />
          <Video parentComponent={this} />
        </div>
      );
    }

    return (
      <div className="App">
        <TopBar />
        <div style={{ minWidth: 1100, overflow: 'hidden' }}>
          <Header />
          <Timeline />
          <Map />
          <Stories />
          { /* <Risk /> */ }
          { /* <Risk2 /> */ }
          <Risk3 />
          <Footer />
          <Footer2 />
        </div>
      </div>
    );
  }
}

export default App;
